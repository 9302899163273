import Image from "next/image";
import externalLinkIcon from 'assets/external-link-dark.svg';
import checkMarkIcon from "assets/check-mark.svg";
import checkMarkGrayIcon from "assets/check-mark-gray.svg";
import { acceptTnc, checkTncStatus } from "data/store";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import { isStaff } from "utils/auth";
import dynamic from "next/dynamic";
import TooltipForNoAccess from "components/TooltipForNoAccess";

const SecureModal = dynamic(() => import('../SecureModal'));

const TncUpdateAlert = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [tncStatusDetails, setTncStatusDetails] = useState({});
  const [isAccepting, setIsAccepting] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    window.addEventListener('tncUpdateAlert', validateTncStatus);

    return () => {
      window.removeEventListener('tncUpdateAlert', validateTncStatus);
    };
  }, [])

  const handleAcceptClick = async () => {
    try {
      if (isStaff()) return;

      setIsAccepting(true);

      await acceptTnc([tncStatus(tncStatusDetails)?.tncId]);

      setIsAccepting(false);
      toast.success('Success!');
      handleClose();
    } catch (err) {
      setIsAccepting(false);
      toast.error(err?.response?.data?.status)
    }
  }

  const validateTncStatus = useCallback(async () => {
    try {
      const tncStatusRes = await checkTncStatus({ entityType: 'dropshipper', tncPage: 'supplierDropshipperTnc' });

      if (!tncStatus(tncStatusRes)?.accepted) setTncStatusDetails(tncStatusRes);

      setIsVisible(!tncStatus(tncStatusRes)?.accepted);
    } catch (err) {
      console.log(err);
    }
  }, [isVisible]);

  const tncStatus = (tncStatus: any) => {
    if (tncStatus?.b2bTnc) {
      return { 
        tncUrl: '/tnc/b2b', 
        accepted: tncStatus.b2bTnc.accepted, 
        tncId: tncStatus.b2bTnc.tncId 
      };
    } else {
      return { 
        tncUrl: '/tnc', 
        accepted: tncStatus.b2cTnc.accepted, 
        tncId: tncStatus.b2cTnc.tncId 
      };
    }
  }

  const handleClose = () => {
    setIsVisible(false);
  }

  return (
    <>
      {isVisible && (
        <SecureModal 
          isVisible={isVisible} 
          onClose={handleClose}    
        >
          <div className="flex flex-col gap-[20px]">
            <div className="flex flex-col gap-[10px]">
              <span className="text-2xl font-bold">Terms & Conditions Update</span>
              <div className="text-sm text-[#424242]">
                <p>{"We've updated our Terms & Conditions to serve you better."}</p>
                <p>Please review and accept them to continue accessing the Roposo Clout.</p>
              </div>
            </div>

            <div>
              <a 
                href={tncStatus(tncStatusDetails)?.tncUrl}
                className="text-sm font-bold flex items-center gap-1"
                target="_blank"
                rel="noreferrer"
              >
                <span className="underline">Review Terms & Conditions</span>
                <div className="w-[16px] h-[16px]">
                  <Image
                    src={externalLinkIcon}
                    alt="externalLinkIcon"
                    height={16}
                    width={16}
                  />
                </div>
              </a>
            </div>

            {/* Divider */}
            <div className="border-t border-[#EDEDED]"></div>

            <div className="flex flex-col gap-[10px]">
              <div>
              <TooltipForNoAccess
                hasAccess={!isStaff()}
                position="top"
                message="Please ask admin to accept TnCs."
              >
                <button 
                  className={`flex items-center gap-[8px] text-sm font-bold text-[white] py-[10px] px-[22px] bg-[#0A0A0A] rounded ${isStaff() || isAccepting ? "bg-theme_1_grey0 text-theme_1_black border-[#e5e7eb] disabled:cursor-not-allowed" : ''}`}
                  onClick={handleAcceptClick}
                  disabled={isStaff() || isAccepting}
                >
                  <div className='flex gap-[8px] items-center justify-center rotate-6'>
                    <Image
                      alt={"checkIcon"}
                      src={isStaff() || isAccepting ? checkMarkGrayIcon : checkMarkIcon}
                      width={16}
                      height={16}
                    />
                  </div>
                  <span>Accept & Continue</span>
                </button>
              </TooltipForNoAccess>
              </div>
              <p className="text-xs text-[#757575]">By accepting, you acknowledge that you have read and agree to the updated terms.</p>
            </div>
          </div>
        </SecureModal>
      )}
    </>
  );
}

export default TncUpdateAlert;
