// import PublicHeader from "components/Header/PublicHeader/Index";
import RedAlert from "components/RedAlert";
import { Screen } from "constants/breakpoints";
import useBreakpoint from "hooks/useBreakpoint";
import dynamic from "next/dynamic";
import Drawer from "components/common/Drawer";
import { isFromNativeApp } from "utils/misc";
import InvestorStoreAccess from "components/AccessValidation/InvestorStoreAccess";

const PublicHeader = dynamic(() => import("components/Header/PublicHeader/Index"), {
  loading: () => <></>,
});

const BottomNav = dynamic(() => import("components/SidebarNav/BottomNav"), { loading: () => <></> });

interface FullWidthLayoutProps {
  children: React.ReactElement;
  showHeader?: boolean;
  isPublicNav?: boolean;
  showBottomNav?: boolean;
  className?: string;
  setMobileDrawer?: boolean;
  mainWrapperClass?: string;
}

const FullPageLayout = ({ children, showHeader, isPublicNav, showBottomNav, className, setMobileDrawer, mainWrapperClass }: FullWidthLayoutProps) => {
  const isSmScreen = useBreakpoint(Screen.SmScreen);

  return (
    <div className={`flex flex-col bg-theme_1_pageBg ${mainWrapperClass || ''}`}>
      <RedAlert
        message="⚠️ We'll be undergoing temporary maintenance on Friday, 23rd Feb 2024, from 1:00 AM to 2:00 AM. We appreciate your understanding."
        expiryDateTime='2024-02-23 02:00:00'
      />
      {showHeader && (
        <div>
          <PublicHeader />
          {!isFromNativeApp() && isSmScreen && <BottomNav isPublicNav={true} />}
        </div>
      )}
      {!isFromNativeApp() && !showHeader && showBottomNav && (
        <div className="bottom-nav">
          <BottomNav isPublicNav={isPublicNav} />
        </div>
      )}
      <div className={`flex max-w-[1215px] m-auto ${className || ''}`}>
        <InvestorStoreAccess>
          {children}
        </InvestorStoreAccess>
      </div>

      {/* Drawer for small screens  */}
      {setMobileDrawer && isSmScreen && <Drawer />}
    </div>
  );
};

export default FullPageLayout;
